<template>
  <!-- <v-card class="overflow-hidden" flat> -->
  <!-- <Header />
    <Body />
    <Footer /> -->
  <NewLanding />
  <!-- </v-card> -->
</template>
<script>
// import Body from '@/components/landingPage/Body.vue';
// import Footer from '@/components/landingPage/Footer.vue';
// import Header from '@/components/landingPage/Header.vue';
import NewLanding from '@/components/landingPage/newLandingPage/Index.vue';

export default {
  name: 'Index',
  components: {
    // Body,
    // Footer,
    // Header,
    NewLanding,
  },
};
</script>
